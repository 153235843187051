// extracted by mini-css-extract-plugin
export var project = "project-module--project--fwL+J";
export var projectsWrapper = "project-module--projectsWrapper--+OOYR";
export var image = "project-module--image--aX2FG";
export var text = "project-module--text--HYPuU";
export var name = "project-module--name--jX8ux";
export var subtitle = "project-module--subtitle--dhSn7";
export var subtitleSmall = "project-module--subtitleSmall--hrbMz";
export var projects = "project-module--projects--EG8QB";
export var image__overlay = "project-module--image__overlay--kOW4y";
export var image__overlayBlur = "project-module--image__overlay--blur--Pr10E";
export var image__overlayPrimary = "project-module--image__overlay--primary--STD6P";
export var logos = "project-module--logos--0nIWv";